.btn-custom{
    background: #096bd8;
    border: medium none;
    display: inline-block;
    font-size: 14px;
    margin-bottom: 0;
    padding: 10px 20px;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    transition: all 0.3s ease 0s;
    vertical-align: middle;
    white-space: nowrap;
    border-radius: 5px;
    position: relative;
    font-family: "cerebri_sansbold";
    color: aliceblue;
}